import React, { useState, useEffect } from "react";
import "./NatureMovement.css";

import Loading from "../../../components/Loading/Loading";

function NatureMovement({ project }) {
  const [isUserInteracted, setIsUserInteracted] = useState(false); // State to track user interaction
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");

  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);
    // Check if project data is cached in sessionStorage
  }, [mediaQuery.matches]);

  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };
  return (
    <div
      className="nature-movement-container"
      onClick={handleDocumentInteraction}
    >
      {project != null ? (
        <React.Fragment>
          <div className="desktop-nature">
            <div
              className="nature-first-row"
              style={{
                backgroundImage: `url('${
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  project.images[0].imageUrl
                }')`,
              }}
            >
              <div className="top-image-nature">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[1].imageUrl
                  }
                  alt={project.images[1].alt}
                />
                <img
                  loading="lazy"
                  className="text-image-nature"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[2].imageUrl
                  }
                  alt={project.images[2].alt}
                />
              </div>
              <div className="bottom-text-image-nature">
                <p>{project.description}</p>
                <div className="right-logo-nature">
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES +
                      project.images[3].imageUrl
                    }
                    alt={project.images[3].alt}
                  />
                </div>
              </div>
            </div>
            <div className="second-row-nature">
              <div className="logo-image-nature">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[1].imageUrl
                  }
                  alt={project.images[1].alt}
                />
              </div>
              <div className="iphone-image-nature">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[4].imageUrl
                  }
                  alt={project.images[4].alt}
                />
                <video
                  preload="true"
                  muted={isSmallScreen}
                  src={
                    process.env.REACT_APP_BACKEND_URL_VIDEOS +
                    project.videos[0].videoUrl +
                    "#t=0.001"
                  }
                  playsInline
                  controls={isSmallScreen}
                  autoPlay={false}
                  loop
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
            </div>
          </div>
          <div className="mobile-nature">
            <p className="nature-mobile-desc">{project.description}</p>
            <div className="nature-mobile-firstrow">
              <div className="nature-image-container">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[1].imageUrl
                  }
                  alt={project.images[1].alt}
                />
                <img
                  loading="lazy"
                  className="text-image-nature-mobile"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[2].imageUrl
                  }
                  alt={project.images[2].alt}
                />
              </div>
            </div>
            <div className="nature-second-row">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  project.images[0].imageUrl
                }
                alt={project.images[0].alt}
              />
            </div>
            <div className="nature-third-row">
              <div className="first-image-nature-third-row">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[1].imageUrl
                  }
                  alt={project.images[1].alt}
                />
              </div>
              <div className="nature-iphone-frame">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    project.images[4].imageUrl
                  }
                  alt={project.images[4].alt}
                />
                <video
                  preload="true"
                  muted={isSmallScreen}
                  src={
                    process.env.REACT_APP_BACKEND_URL_VIDEOS +
                    project.videos[0].videoUrl +
                    "#t=0.001"
                  }
                  playsInline
                  controls={isSmallScreen}
                  autoPlay={false}
                  loop
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default NatureMovement;

import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { HashLink } from "react-router-hash-link";

function NavBar({ firstProjectName }) {
  const [visibleRight, setVisibleRight] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const sidebarLinks = [
    { to: "/#featured", label: "FEATURED" },
    {
      to: "/project/" + firstProjectName.replace(/\s+/g, "-").toLowerCase(),
      label: "WORKS",
    },
    { to: "/#experience", label: "EXPERIENCE" },
    { to: "/services", label: "SERVICES" },
    { to: "/#LetsConnect", label: "CONTACT" },
  ];
  const customIcon = (
    <React.Fragment>
      <img className="p-icon" alt="close icon" src="/Icons/x.svg" />
    </React.Fragment>
  );
  const handleLinkClick = () => {
    setVisibleRight(false); // Close the sidebar when a link is clicked
  };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="navbar-container-mobile-desktop">
      <div className={`navbarMainContainer ${isSticky ? "sticky" : ""}`}>
        <div className="nav-container d-flex justify-content-between align-items-center">
          <div className="leftside-links d-flex gap-4">
            <a
              href="https://www.instagram.com/nour___studio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTAGRAM
            </a>
            <HashLink smooth to="/#experience">
              EXPERIENCE
            </HashLink>
            <Link to="/services">SERVICES</Link>
          </div>
          <Link to="/" className="logo">
            <img
              loading="lazy"
              src={process.env.PUBLIC_URL + "/Logo/nourjabr.png"}
              alt="website logo"
            />
          </Link>
          <div className="rightside-links d-flex gap-4 justify-content-end">
            <Link
              to={
                "/project/" +
                firstProjectName.replace(/\s+/g, "-").toLowerCase()
              }
            >
              WORKS
            </Link>
            <HashLink smooth to="/#LetsConnect">
              SAY HI
            </HashLink>
          </div>
        </div>
      </div>
      <div className="navbar-mobile-version">
        <Link to="/" className="logo">
          <img
            loading="lazy"
            src={process.env.PUBLIC_URL + "/Logo/nourjabr.png"}
            alt="website logo"
          />
        </Link>
        <div className="right-menu-bar">
          <Button
            icon="pi pi-align-justify"
            onClick={() => setVisibleRight(true)}
          />

          <Sidebar
            className="menubar-mobile"
            visible={visibleRight}
            position="right"
            onHide={() => setVisibleRight(false)}
            closeIcon={customIcon}
          >
            <div className="right-side-bar">
              {sidebarLinks.map((link, index) => (
                <HashLink
                  key={index}
                  smooth
                  to={link.to}
                  onClick={handleLinkClick}
                >
                  {link.label}
                </HashLink>
              ))}
            </div>
          </Sidebar>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

import React, { useState, useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loading from "../MainWebsite/components/Loading/Loading";
import NavBar from "../MainWebsite/shared/NavBar";
import Home from "../MainWebsite/pages/home/home";
import Halu from "../MainWebsite/pages/projects/Halu/Halu";
import Lumi from "../MainWebsite/pages/projects/Lumi/Lumi";
import MarianneJabr from "../MainWebsite/pages/projects/MarianneJabr/MarianneJabr";
import SmellyCat from "../MainWebsite/pages/projects/SmellyCat/SmellyCat";
import AiExperimenting from "../MainWebsite/pages/projects/AiExperimenting/AiExperimenting";
import Baladi from "../MainWebsite/pages/projects/Baladi/Baladi";
import Mac from "../MainWebsite/pages/projects/MAC/Mac";
import Folie from "../MainWebsite/pages/projects/Folie/Folie";
import NatureMovement from "../MainWebsite/pages/projects/NatureMovement/NatureMovement";
import ProjectDetails from "../MainWebsite/pages/projects/DynamicProjectDetails/ProjectDetails";
import PageNotFound from "../PageNotFound/PageNotFound";
import { ProjectsContext } from "../hooks/ProjectsProvider";
import Services from "../MainWebsite/pages/Services/Services";

function MainRoutes() {
  const { projects, loading } = useContext(ProjectsContext);
  const navigate = useNavigate();
  const location = useLocation();
  // const [projects, setProjects] = useState(null);
  const [projectIndex, setProjectIndex] = useState(0);

  const goToPreviousProject = () => {
    const newIndex = (projectIndex - 1 + projects.length) % projects.length;
    setProjectIndex(newIndex);
    navigate(
      `/project/${projects[newIndex].name.replace(/\s+/g, "-").toLowerCase()}`
    );
  };

  const goToNextProject = () => {
    const newIndex = (projectIndex + 1) % projects.length;
    setProjectIndex(newIndex);
    navigate(
      `/project/${projects[newIndex].name.replace(/\s+/g, "-").toLowerCase()}`
    );
  };

  const isProjectPage = location.pathname.startsWith("/project/");
  const getFirstProjectName = () => {
    if (projects && projects.length > 0) {
      return projects[0].name;
    }
    return "";
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <NavBar firstProjectName={getFirstProjectName()} />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<PageNotFound />} />
        {projects != null &&
          projects.map((project) => {
            if (project.name.toLowerCase().includes("halu")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<Halu project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("lumi")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<Lumi project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("marianne")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<MarianneJabr project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("smelly")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<SmellyCat project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("experimenting")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<AiExperimenting project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("baladi")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<Baladi project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("mac")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<Mac project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("folie")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<Folie project={project} />}
                />
              );
            } else if (project.name.toLowerCase().includes("movement")) {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<NatureMovement project={project} />}
                />
              );
            } else {
              return (
                <Route
                  key={project.name}
                  path={`/project/${project.name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}`}
                  element={<ProjectDetails project={project} />}
                />
              );
            }
          })}
      </Routes>
      {isProjectPage && (
        <div className="arrows-projects">
          <div
            className="arrow-left"
            onClick={goToPreviousProject}
            style={{
              position: "fixed",
              bottom: "70px",
              left: "10px",
              width: "110px",
              height: "110px",
            }}
          >
            <img
              src="/Icons/arrow-01.svg"
              alt="left arrow"
              style={{ width: "100%" }}
            />
          </div>

          <div
            className="right-arrow"
            onClick={goToNextProject}
            style={{
              position: "fixed",
              bottom: "70px",
              right: "10px",
              width: "110px",
              height: "110px",
            }}
          >
            <img
              src="/Icons/arrow-02.svg"
              alt="right arrow"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MainRoutes;

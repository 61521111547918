import React from "react";
import "./SmellyCat.css";

import Loading from "../../../components/Loading/Loading";

function SmellyCat({ project }) {
  return (
    <div className="smellycat-container">
      {project != null ? (
        <React.Fragment>
          <div className="smellycat-first-row">
            <div className="smelly-desc-mobile">{project.description}</div>
            <div className="cat-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  project.images[0].imageUrl
                }
                alt={project.images[0].alt}
              />
            </div>
            <div className="smelly-description">{project.description}</div>
          </div>
          <div
            className="smellycat-second-row"
            style={{
              backgroundImage: `url('${
                process.env.REACT_APP_BACKEND_URL_IMAGES +
                project.images[1].imageUrl
              }')`,
            }}
          >
            <div className="smellycat-logo-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  project.images[3].imageUrl
                }
                alt={project.images[3].alt}
              />
            </div>
            <div className="smellycat-multiple-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  project.images[2].imageUrl
                }
                alt={project.images[2].alt}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default SmellyCat;

import React from "react";
import "./Services.css";

function Services() {
  return (
    <div className="services-container">
      <div className="servicesContent">
        <p className="branding">BRANDING</p>
        <p className="socialMedia">SOCIAL MEDIA MANAGEMENT</p>
        <p className="websitedesign">WEBSITE DESIGN</p>
        <p className="artdirection">ART DIRECTION</p>
        <p className="paidmedia">PAID MEDIA VISUALS</p>
        <p className="alotmore">AND A LOT MORE...</p>
      </div>
    </div>
  );
}

export default Services;
